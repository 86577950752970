<template>
    <fhiry-page class="recruit" :banners="banners">
        <div class="recruit-wrapper">
            <div class="inner-wrapper">
                <div class="station" v-for="(station, i) in stations" :key="i">
                    <header class="station-header">
                        <h1>{{ station.stationName }}</h1>
                    </header>
                    <section class="duty station-content">
                        <h2>【岗位职责】</h2>
                        <ul class="list">
                            <li v-for="(du, idx) in station.duty" :key="idx">
                                <span>{{ idx + 1 }}.</span>
                                <span>{{ du }}</span>
                            </li>
                        </ul>
                    </section>
                    <section class="require station-content">
                        <h2>【岗位要求】</h2>
                        <ul class="list">
                            <li v-for="(re, idx) in station.require" :key="idx">
                                <span>{{ idx + 1 }}.</span>
                                <span>{{ re }}</span>
                            </li>
                        </ul>
                    </section>
                </div> 
            </div>
        </div>
    </fhiry-page>
</template>

<script>
    // 人才招聘
    // 组件
    import FhiryPage from '@/components/FhiryPage';

    import { getRecruit } from '@/api/request';

    export default {
        name: 'recruit',
        created () {
            this.getRecruitAjax();
        },
        data () {
            return {
                banners: [],
                stations: []
            }
        },
        methods: {
            getRecruitAjax () {
                getRecruit().then(res => {
                    this.banners = res.banners;
                    this.stations = res.stations;
                })
            }
        },
        components: {
            FhiryPage
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .recruit {
        .recruit-wrapper {
            padding: 30px 0;
            .inner-wrapper {
                padding: 80px;
                margin: 0 auto;
                width: @limit-width;
                background-color: #FFF;
                border-radius: 5px;
                .station {
                    padding-bottom: 80px;
                    margin-bottom: 80px;
                    border-bottom: 2px dotted #eee;
                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                    .station-header {
                        margin-bottom: 50px;
                        h1 {
                            margin: 0 auto;
                            padding: 6px 0;
                            width: 200px;
                            text-align: center;
                            color: #FFF;
                            font-size: 20px;
                            background-color: @theme-color;
                        }
                    }
                    .station-content {
                        margin-bottom: 30px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        h2 {
                            position: relative;
                            left: -8px;
                            margin-bottom: 10px;
                            font-size: 18px;
                        }
                        .list {
                            li {
                                display: flex;
                                align-items: flex-start;
                                font-size: 14px;
                                line-height: 2;
                                span {
                                    &:first-child {
                                        position: relative;
                                        top: 1px;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .recruit {
            .recruit-wrapper {
                padding: 0.53333rem 0.26666rem;
                .inner-wrapper {
                    padding: 0.8rem 0.5333rem;
                    width: 100%;
                    .station {
                        margin-bottom: 0.8rem;
                        padding-bottom: 0.8rem;
                        .station-header {
                            margin-bottom: 0.8rem;
                            h1 {
                                width: 4.2666rem;
                                font-size: 0.42666rem;
                                border-radius: 0.08rem;
                            }
                        }
                        .station-content {
                            margin-bottom: 0.37333rem;
                            h2 {
                                font-size: 0.42666rem;
                            }
                            .list {
                                li {
                                    font-size: 0.32rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>